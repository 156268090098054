.login-window-wrapper {
    border-radius: 10px;
    height: 600px !important;
    margin: 30px auto !important;
    padding-right: 35px;
    padding-left: 35px;
}

.login-window-wrapper .webix_win_head {
    border-bottom: 0;
    background-color: transparent !important;
}

.login-container {
    width: 100% !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.login__form__Wrapper {
    width: 40%;
    margin: 0 auto !important;
    border: solid 2px red;
}

.sign__header {
    font-size: 1.5rem;
    color: #000;
    font-weight: 300;
    padding-left: 0px;
    text-align: start;
    padding-bottom: 10px !important;
}

.login__form__Wrapper .webix_el_box input {
    width: 89% !important;
    background-color: #eee !important;
    padding: 7px 10px;
    border: 0 !important;
    border-radius: 50px !important;
    margin: 0 auto !important;
    font-weight: 100;
    box-shadow: 0 2px 2px rgb(230, 230, 230);
}

.login__form__Wrapper .webix_secondary .webix_button {
    width: 88%;
    background-color: var(--anchorColor) !important;
    color: #fff !important;
    border-radius: 50px !important;
    margin: 0 auto !important;
    font-weight: 100;
    margin-left: -40px !important;
}

.login__form__Wrapper .webix_el_button .webix_el_box {
    padding-left: 0 !important;
}

.forgot--password {
    width: 100%;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 20px !important;
}

.login__form__Wrapper input::placeholder {
    font-size: 0.8rem;
    color: #777777;
}

.col__two__wrapper__template {
    width: 50% !important;
}

.col__two__wrapper {
    text-align: center;
    width: 90%;
    background: #fff;
}

.col__two__wrapper img {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.col__two__wrapper h3 {
    font-size: 1.5em;
    color: #000;
    font-weight: 300;
}

.col__two__wrapper p {
    font-size: 1em;
    color: #333;
    width: 100%;
    margin: 0 auto;
}

@media screen and (min-width: 300px) {
}
