.popup__wrapper {
    margin-top: 20px;
}

/*---------------------------------------------
  For task error modal in project-task folder
-----------------------------------------------*/
.task_error_modal {
    margin-top: 100px !important;
}

.error,
.success,
.webix_message.webix_error,
.webix_message.webix_success {
    width: 400px;
    height: 100px;
    background-color: #fff !important;
    color: #000 !important;
    padding-top: 10px !important;
    top: 30px;
    text-align: center;
    border: none;
    border-left: solid 2px var(--buttonBackground);
}

.webix_message.webix_error,
.webix_message.webix_success {
    margin-right: 50px !important;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative !important;
    right: 0 !important;
    left: 0;
    top: 0 !important;
    margin-left: -180px !important;
}

.success,
.webix_message.webix_success {
    border-left: solid 2px #008000 !important;
}
