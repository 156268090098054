.webix_win_head {
  background-color: var(--windorColor) !important;
  color: var(--primary);
}

[class="webix_view webix_header webix_win_title"] {
  background-color: var(--windorColor) !important;
  color: var(--primary);
}

.login__window__wrapper .webix_win_head,
.login__window__wrapper .webix_win_head [class="webix_view webix_layout_line"] {
  background-color: transparent !important;
}

.add__new__customer__or__company_form input {
  background-color: transparent !important;
  padding: 10px !important;
}

.add__new__customer__or__company_form input:invalid {
  border: solid 1px var(--textColor) !important;
}

.add__newmember__save__btn .webix_button {
  cursor: pointer;
  color: var(--primary) !important;
  background-color: var(--anchorColor) !important;
  border: solid 1px #dfdfdf !important;
  box-shadow: 0 0 2px 1px #eee;
  border-radius: 50px !important;
  font-size: 0.8rem;
  font-weight: 100;
  padding: 0.5rem 1rem !important;
}

.new-member-window {
  margin-top: 30px;
}
