.inbox__header {
  background-color: var(--primary);
  color: #000;
  font-size: 1.5rem;
  font-family: "Metal Mania", cursive;
  font-weight: 550;
  max-height: 40px;
}

.new__message__button {
  margin-left: -20px !important;
}
