:root {
    --primary: #fff;
    --viewBodyColor: #DDDDDD;
    --buttonBackground: #d33946;
    --textColor: #777585 ;
    --iconColor:#70b2c2;
    --windorColor: #525252;
    --anchorColor: #70b2c2;
    --headingBorderBottomColor: #70b2c2;
    --menuSelectedItemColor:#f4f5f9;
    --menuHoveredItemColor:#EDEFF5;
    --boxHeaderBackground:#f4f5f9;
    --warningColor:#FF8839;
}



html, body {
    color:var(--textColor);
}


.webix_view.login-container {
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(112,178,194,1) 0%, rgba(205,220,249,1) 100%, rgba(201,226,255,1) 100%);
}

.login-form-container.webix_view {
    background-color:var(--primary);
    border-radius:8px;
}

.webix_view .login-form-container-h1 {
    border-radius:0px;
}

.login-container h1 {
    color: var(--windorColor);
    line-height: 1.5;
}

.login-container-input-label {
    font-size: 14px;
    color: var(--windorColor);
    line-height: 1.5;
    padding-left: 7px;
}

.login-container-input .webix_el_box input {
    font-size: 16px;
    color: var(--textColor);
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 55px;
    background: 0 0;
    padding: 0 0 31px 18px;
    border:none;
}

.login-container-input-icon  .webix_el_box {
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom: 2px solid #d9d9d9;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
    font-size:20px;
}

.login-form-container-logo {
    background-color: transparent;
    text-align: center;
}

.login-container-input-box.webix_view {
    border-bottom: 2px solid #d9d9d9;
    border-radius:0;
}

.login-form-container-logo img {
    max-height: 120px;
}

.login-form-forgot-password .webix_template {
    padding:0;
    text-align: right;
}

.login-form-forgot-password a {
    text-decoration: none;
    color:var(--textColor);
}

.login-form-forgot-password a:hover {
    text-decoration: underline;
    color:var(--textColor);
}

.webix_el_label .webix_el_box {
    font-size:0.88rem;
    font-weight: 400;
}

.webix_view {
    font-size: 0.88rem;
    color:var(--textColor);
    font-weight: 400;
}

.webix_inp_label, .webix_inp_top_label, legend.webix_forminput_label, .webix_el_label .webix_el_box {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color:var(--textColor);
    letter-spacing: 0.2px;
}

li {
    display: table;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.webix_sidebar {
    padding-top: 13px !important;
}


.webix_sidebar .webix_tree_item, .webix_sidebar .webix_tree_branch_1 > .webix_tree_item {
    color:var(--textColor);
}

.webix_sidebar .webix_tree_item.webix_selected, .webix_sidebar .webix_tree_item.webix_sidebar_selected, .webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_icon,  .webix_sidebar .webix_tree_item.webix_sidebar_selected .webix_sidebar_icon{
    color:var(--anchorColor);
}

.webix_sidebar .webix_tree_item.webix_selected, .webix_sidebar .webix_tree_item.webix_sidebar_selected, .webix_sidebar .webix_tree_item.webix_selected, .webix_sidebar .webix_tree_item.webix_sidebar_selected, .webix_sidebar.webix_sidebar_expanded .webix_tree_item.webix_sidebar_selected {
    background-color:var(--menuSelectedItemColor);
    color:var(--anchorColor);
}

.webix_sidebar .webix_tree_item.webix_selected span {
    background-color:var(--menuSelectedItemColor);
    color:var(--anchorColor);
}

.webix_sidebar.webix_sidebar_expanded .webix_tree_branch_2 .webix_tree_item {
    padding-left:44px;
}

.webix_sidebar.webix_sidebar_expanded .webix_tree_branch_3 .webix_tree_item {
    padding-left:44px;
}

.webix_sidebar .webix_tree_branch_2 .webix_tree_item {
    padding-left:14px;
}

.webix_sidebar .webix_tree_branch_3 .webix_tree_item {
    padding-left:14px;
}

.webix_list_item.webix_selected {
    color:var(--textColor);
    background-color:var(--menuSelectedItemColor);
}

.webix_secondary .webix_button .webix_icon, .webix_secondary .webix_button .webix_img_btn_text, .webix_secondary .webix_button .webix_icon_btn {
    color:var(--textColor);
}

.invoice-details-buttons .webix_secondary .webix_button .webix_icon_btn  {
    color:var(--anchorColor);
}
  
.webix_secondary .webix_button {
    background:var(--primary);
    color:var(--anchorColor);
}

.webix_secondary .webix_button:hover {
    background:var(--primary);
}

.webix_el_box .webix_button.webix_img_btn {
    background:transparent;
}

.webix_dtable .webix_ss_header .webix_hcolumn div.webix_last_row, .webix_dtable .webix_ss_header .webix_span.webix_last_row  {
    border-bottom: 1px solid var(--viewBodyColor);
}
.webix_dtable .webix_ss_footer .webix_hcolumn .webix_hcell {
    border-top: 1px solid var(--viewBodyColor) !important;
}

.webix_dtable .webix_ss_footer .webix_hcell[row="0"] {
    border-top: 1px solid var(--viewBodyColor) !important;
}

.webix_accordionitem_header {
    color:var(--textColor);
}

.cog-button-big .webix_el_box .webix_button {
    background:var(--anchorColor);
    color:var(--primary);
}


.cog-button-big.disabled .webix_el_box .webix_button {
    background:var(--anchorColor);
    color:var(--primary);
    cursor: not-allowed;
    opacity:0.5;
}

.cog-button-big-red .webix_el_box .webix_button {
    background:var(--buttonBackground);
    color:var(--primary);
}

.cog-button-big-red.disabled .webix_el_box .webix_button {
    background:var(--buttonBackground);
    color:var(--primary);
    cursor: not-allowed;
    opacity:0.3 !important;
}

.display-none {
    cursor: not-allowed;
    display: none; 
}

.notification-icon {
    width:24px;
}

.webix_el_search input:focus, .webix_el_text input:focus, .webix_el_combo input:focus, .webix_el_colorpicker input:focus, .webix_el_datepicker input:focus {
    border-color:var(--anchorColor);
}

.toptab.active .webix_button {
    background-color: #E7E9F2;
    border:none;
}

.toptab .webix_button:hover, .toptab.active .webix_button:hover {
    background-color:var(--menuHoveredItemColor);
}

.padding-left-10 {
    padding-left:10px !important;
}

.float-right {
    text-align: right;
}

.icon-red {
    color:var(--buttonBackground);
}

.text-green {
    color:var(--anchorColor);
}

.margin-left-10 {
    margin-left:10px !important;
}

.cog-icon, .cog-icon span, .cog-icon .webix_icon_btn {
    font-size: 1rem;
    color:var(--anchorColor) !important;
    cursor: pointer;
}

.cog-icon-green .webix_el_box .webix_button {
    font-size: 1rem;
    background-color:var(--anchorColor) !important;
    color:var(--primary);
    cursor: pointer;
}

.cog-icon-dark, .cog-icon-dark span, .cog-icon .webix_icon_btn {
    font-size: 1rem;
    color:var(--textColor) !important;
    cursor: pointer;
    margin-top:0.5px;
}

.noclick {
    cursor: default;
}

.webix-datatable-link {
    color: var(--anchorColor);
    cursor: pointer;
}

.mandatory input, .mandatory .webix_inp_static, .mandatory .webix_multicombo_input {
    border-color:#f9873a;
}

.webix_icon, .webix_input_icon {
    font-size:0.96rem;
}
 
.cog-clickable-table .webix_column > div, .webix_table_cell {
    cursor: pointer;
}

.search-table .webix_column > div, .webix_table_cell {
    cursor: pointer;
}

.cognovis-basic-modal .cognovis-file-modal-zip-icon .webix_el_box .webix_button, .cognovis-basic-modal .cognovis-file-modal-zip-icon .webix_el_box .webix_button:hover {
    text-align:right;
    background-color: transparent;
}

.cog-datatable-hover {
    background:var(--menuHoveredItemColor);
}

.cog-button-big-notes .webix_el_box .webix_button {
    background:var(--anchorColor);
    color:var(--primary);
}

.cog-button-big-notes.exists .webix_el_box .webix_button {
    background:var(--anchorColor);
    color:var(--primary);
}

.action-buttons-container .webix_el_box .webix_button, .action-buttons-container .webix_el_box .webix_button:hover {
    background-color: var(--iconColor);
    color:var(--primary);
}

.cog-button-big-notes.exists .webix_el_box .webix_button {
    background:var(--textColor);
    color:var(--primary);
}

.cog-button-big-inverted {
    background:var(--menuSelectedItemColor);
    border-radius:10px;
    border:var(--anchorColor);
}

.cog-main-toolbar .webix_view {
    border:none;
}

.webix_ss_header, .webix_hs_center, .webix_hcolumn {
    color:var(--textColor);
}

.webix_icon_button:hover::before {
    background-color: transparent;
}

.webix_icon_button::before {
    background-color: transparent;
}

.wxi-close {
    color:var(--textColor) !important;
    opacity:0.75 !important;
}

.wxi-close:hover {
    color:var(--textColor) !important;
    opacity:1 !important;
}


.text-link {
    color:#68b3c6;
    text-decoration: underline;
    cursor: pointer;
}

.text-link-no-effect {
    color:#68b3c6;
    cursor: pointer;
}

.text-link:hover {
    color:#68b3c6;
    text-decoration: none;
}

.orange-text-link {
    color:#f9873a;
    text-decoration: underline;
    cursor: pointer;
}

.orange-text-link:hover {
    color:#f9873a;
    text-decoration: none;
}

.assignment-overview-icon {
    width:24px;
    height: 24px;
    font-size: 1.4em;
    cursor:pointer;
}

.assignment-overview-icon.lightgrey {
    color:#dbd9d9;
    opacity:1;
    cursor:auto;
}

.assignment-overview-icon.orange {
    color:#f9873a;
}

.assignment-overview-icon.green, .assignment-overview-icon.blue {
    color:#68b3c6;
}

.assignment-overview-icon.darkgrey {
    color:#4b4a54;
}

.assignment-overview-icon.yellow {
    color:#c9bd38;
}

.assignment-submenu-icon {
    font-size: 25px;
    margin-top: 2px;
    cursor: pointer;
}

.webix_view.webix_toolbar.cog-main-toolbar.webix_layout_toolbar {
    border-bottom:2px solid var(--anchorColor) !important;
}

.webix_accordionitem.collapsed .webix_accordionitem_header, .webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_button {
    color:var(--anchorColor);
    background-color: var(--menuSelectedItemColor);
}

.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_button {
    background-color: transparent;
}

.cog-content {
    background:var(--viewBodyColor) !important;
}


.cog-content-scroll {
    background:var(--viewBodyColor) !important;
    height:auto !important;
}

.cog-box-content {
    background:var(--primary);
}

.cog-box-header {
    font-size:1.1rem;
    font-weight: 500;
    border-bottom:2px solid var(--anchorColor) !important;
    background:var(--menuSelectedItemColor);
    margin-bottom:1px;
}

.cog-box-header-no-border {
    font-size:1.1rem;
    font-weight: 500;
    background:var(--menuSelectedItemColor);
    margin-bottom:1px;
}

.assigment-details-layout .cog-box-header {
    font-size:0.9rem;
    font-weight: 200;  
}

.cog-pm-project-main-header {
    background:var(--viewBodyColor);
}

.cog-pm-project-action-button .webix_el_box .webix_button {
    background:transparent;
}

.cog-pm-project-action-button .webix_el_box .webix_button span {
    color:var(--anchorColor);
    font-size:1rem;
    text-align: center;
}

.cog-searchfl-form {
    margin-top: -5px !important;
    height: 54px !important;
}

.webix_dtable .webix_ss_footer .webix_hcolumn {
    color:var(--textColor);
}

.stars {
    position: absolute;
    margin-top:-5px;
    left:11px;
    float: left;
    font-size: 13pt;
	height: 1em;
    line-height: 1em;
}

.stars:before {	
    content: "\2606\2606\2606\2606\2606";
    float: left;
    z-index: 1;
    color:#f9c246;
}

.stars .percent {
    position: absolute;
    left: 0;
    float: left;
    overflow: hidden;
    z-index: -1;
}


.stars .percent:after {	
    content: "\2605\2605\2605\2605\2605";
    color:#f9c246;
}

.table-action-icon {
    font-size: 1.15rem;
    margin-top:8px;
    text-align: left;
    cursor: pointer;
}

.table-action-icon.reject {
    color:#fa883a !important;
}

.table-action-icon.accept {
    color:#6cb774 !important;
}

.table-action-icon.disabled {
    color:#475466 !important;
    cursor:not-allowed;
    opacity:0.2;
}

.disabled {
    opacity:0.33;
    cursor:not-allowed;
}

.webix_sidebar.webix_sidebar_expanded .webix_tree_branch_2 .webix_tree_item {
    padding-left:44px;
}


.webix_sidebar .webix_tree_branch_2 .webix_tree_item {
    padding-left:14px;
}

.logo {
    max-height:40px;
}


.red-box-fa--icon {
    color: #c1092b !important;
}

.green-box-fa--icon {
    color: #36c237 !important;
}

.avatar {
    object-fit: contain;
    width: 40px;
    height: 40px;
}

.anon-avatar-bg, .anon-avatar-comppany-bg {
    z-index: 2;
}

.anon-avatar-bg img {
    background-position: center center;
    background-size: 60%;
    background-repeat: no-repeat;
    background-image:url("../../assets/images/avatar.png");
    border-radius: 50%;
}


.anon-avatar-comppany-bg img {
    background-position: center center;
    background-size: 60%;
    background-repeat: no-repeat;
    background-image:url("../../assets/images/company_avatar.png");
    border-radius: 50%;
}


.quote-invoice-toggler .webix_el_box .webix_button, .discounts-add-button .webix_el_box .webix_button:focus {
    background-color: #70b2c2;
    color:#fff;
}

.quote-invoice-toggler-disabled .webix_el_box .webix_button, .discounts-add-button .webix_el_box .webix_button:focus {
    color:#99989b;
    background-color:#d8d8d8;
}

.discount-box {
    width: 36px;
    height: 30px;
    background-color: transparent;
    border: 1px solid #70b2c2;
    text-align: center;
    padding:0;
    cursor: pointer;
}

.discount-box span {
    font-size: 13px !important;
    color: #70b2c2 !important;
    margin-top:7px;
}

.discount-box.active {
    background-color:#70b2c2;
}

.discount-box.active span {
    color: #ffffff !important;
}

.discounts-add-button .webix_el_box .webix_button, .discounts-add-button .webix_el_box .webix_button:focus {
    border:2px dotted #b5b5b5;
    background-color: transparent;
}

.discounts-add-button .webix_el_box .webix_button:focus {
    border:2px dotted #b5b5b5 !important;
    background-color: transparent !important;
}
.discounts-add-button .webix_el_box .webix_button span {
    color:#475466 !important;
}

.newsletter-button .webix_button, .newsletter-button .webix_button:hover, .newsletter-button .webix_button:focus {
    background-color:#f9873a;
    color:var(--primary);
    font-size:0.86rem;
}


.assignment-actions-normal, .assignment-actions-normal .webix_button, .assignment-actions-normal .webix_button:hover, .assignment-actions-normal.webix_secondary .webix_button {
    background-color:var(--anchorColor);
    color:#ffffff;
    margin-bottom:5px;
}

.blue-box-fa--icon {
    color:var(--anchorColor);
    cursor: pointer;
}

.assignment-actions-reject, .assignment-actions-reject .webix_button, .assignment-actions-reject .webix_button:hover, .assignment-actions-reject.webix_secondary .webix_button{
    background-color:#f9873a;
    color:var(--primary);
    margin-bottom:5px;
}


.assignment-actions-accept, .assignment-actions-accept .webix_button, .assignment-actions-accept .webix_button:hover, .assignment-actions-accept.webix_secondary .webix_button {
    background-color:#36c237;
    color:var(--primary);
    margin-bottom:5px;
}

.telephone-link a, .email-link a, .general-link a{
    color:var(--anchorColor);
    text-decoration: underline;
}

.telephone-link a:hover, .email-link a:hover, .general-link a:hover {
    color:var(--anchorColor);
    text-decoration: none;
}

.rounded-number-indicator {
    background-color: #F58A49;
    border-radius: 50%;
    position: relative;
    margin-left: 10px;
    padding: 2px 6px;
    color:var(--primary);
    font-size: 0.7em;
    top: -2px;
}


.cog-calendar-day-marker {
    border-radius: 40px;
    /*height: 26px;*/
}

.cog-calendar-day-marker.noentries {
    background-color:#f9a29a;
}

.cog-calendar-day-marker.notalotofentries {
    background-color:#f7b58c;
}

.cog-calendar-day-marker.absence {
    background-color:#99FF33;
}


.cog-search-fitler-button .webix_el_box .webix_button{
    color:var(--viewBodyColor);
    font-weight: 200;
    font-size: 0.8rem;
    background-color: var(--menuSelectedItemColor);
}

.cog-search-fitler-button.selected .webix_el_box .webix_button{
    color:var(--textColor);
    font-weight: 400;
    background-color: var(--menuSelectedItemColor);
}

.webix_popup_title, .webix_popup_text {
    color:var(--textColor);
}

.webix_popup_button.confirm { 
    background-color:var(--anchorColor);
}

.webix_popup_button.confirm { 
    border-color:var(--anchorColor);
}

.webix_popup_button {
    color:var(--anchorColor);
}

.webix_confirm-warning .webix_popup_title {
    box-shadow: inset 0 4px var(--warningColor);
}

.price-display {
    text-align: right;
}



.greyedout {
    cursor:not-allowed;
    opacity:0.2;
}

.opacity-0-2 {
    opacity:0.2;
}

.cog-logo-container {
    vertical-align: middle !important;
    line-height: 49px;
}

.cog-logo-container img {
    max-height: 25px;
}

.ck-focused {
    border:1px solid transparent !important;
}

.ck-body {
    box-shadow:var(--ck-inner-shadow),0,0 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.translation-circle {
    cursor: pointer;
    align-items: center;
    vertical-align: middle;
}

.translation-circle.red {
    color:red;
}

.translation-circle.green {
    color:green;
}

.translation-circle.orange {
    color:orange;
}

.cog-button-icon.disabled {
    opacity: 0.3;
}

.cog-translator-box.red input {
    color:red
}

.cog-translator-box.green input {
    color:green;
}

.cog-translator-box.orange input {
    color:#f9873a;
}

.clear {
    width:100%;
    clear:both;
}

.webix_alert .webix_popup_text {
    box-shadow: inset 0 4px var(--anchorColor);
}

.cog-import-fp-trados-modal.webix_modal_box, .cog-remove-tasks-modal.webix_modal_box {
    overflow-y: scroll;
    max-height: 700px;
    width:auto;
}

.modal-text-content {
    text-align: center;
    padding:30px 0;
}

div[view_id="npComment"] {
    border-width: 1px 1px 1px !important;
}

.webix_view.webix_window.webix_popup, .webix_view.webix_list, .webix_win_body > .webix_view {
    min-width:0 !important;
}

.webix_richtext_inp_label .webix_template {
    color:var(--textColor);
    font-weight: normal;
}

.batch-edit-name-icon {
    font-size:13px;
    cursor:pointer;
}


.assignment-overview-status-icon {
    position: relative;
    top: -1px;
    font-size: 1.1em;
}

.linked-objects-popup {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}

.linked-objects-popup .webix_view .webix_dtable {
   background-color: var(--primary);
   color:var(--primary);
   box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
   border-radius:4px;
   border:4px solid var(--anchorColor);
}


.linked-objects-popup .webix_cell {
    border:none !important;
}

.linked-objects-popup.transparent {
    background-color: transparent !important;
    box-shadow: none;
}

.linked-objects-modal-triangle {
    font-size:20px;
    color:var(--anchorColor);
    display: block;
    top:-3px;
    left:2px;
    position: relative;
}

.huge-fa-icon {
    font-size:9rem;
}

.info-label {
    color: var(--windorColor);
    text-transform: uppercase;
    font-size: 0.66rem;
    top: -5px;
    position: relative;
}

.info-value {
    margin-bottom:2px;
}

.total-hours {
    margin-top:7px;
}

.total-hours.noentries {
    color:#f9a29a;
}

.total-hours.notalotofentries {
    color:#f7b58c;
}

.slide-in-blurred-top {
	-webkit-animation: slide-in-blurred-top 0.4s linear 0.2s both;
	animation: slide-in-blurred-top 0.4s linear 0.5s both;
}

.slide-in-blurred-bottom {
	-webkit-animation: slide-in-blurred-bottom 0.4s linear 0.5s both;
	animation: slide-in-blurred-bottom 0.4s linear 0.5s both;
}

.slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-bottom 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-out-top {
	-webkit-animation: slide-out-top 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-top 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}


 @-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-30px) scaleY(1) scaleX(1);
        transform: translateY(-30px) scaleY(1) scaleX(1);
        -webkit-filter: blur(5px);
        filter: blur(5px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
  }
  @keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-30px) scaleY(1) scaleX(1);
        transform: translateY(-30px) scaleY(1) scaleX(1);
        -webkit-filter: blur(5px);
        filter: blur(5px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(-40px) scaleY(1) scaleX(1);
        transform: translateY(-40px) scaleY(1) scaleX(1);
        -webkit-filter: blur(5px);
        filter: blur(5px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
  }
  @keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(30px) scaleY(1) scaleX(1);
        transform: translateY(30px) scaleY(1) scaleX(1);
        -webkit-filter: blur(5px);
        filter: blur(5px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
  }


@-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
      opacity: 0;
    }
  }
  

  @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
      opacity: 0;
    }
  }
  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
      opacity: 0;
    }
  }
  
.webix_ss_vscroll_header {
    border:none !important;
}
    
.webix_view .webix_dtable .webix_ss_hscroll.webix_vscroll_x {
    overflow-x: hidden !important;
}

.webix_view .report-table.webix_dtable .webix_ss_hscroll.webix_vscroll_x {
    overflow-x: scroll !important;
}

.webix_ck_editor.ck.ck-content.ck-editor__editable {
    height: 100% !important;
}

.error-message {
    font-size:0.70rem;
    margin-left:173px !important;
    color:var(--buttonBackground);
}

.webix_all_tabs .webix_item_tab {
    padding:0px 2px;
    color:var(--anchorColor);
    font-weight: 100;
    background-color:var(--menuSelectedItemColor);
   
}

.webix_all_tabs .webix_item_tab.webix_item_tab.webix_selected {
   box-shadow: none;
   font-weight: 100;
   background-color:var(--menuHoveredItemColor);
}

.webix_item_tab {
    border-top:3px solid var(--viewBodyColor) !important;
    border-bottom:3px solid var(--viewBodyColor) !important;
    border-right:2px solid var(--viewBodyColor) !important;
    border-left:2px solid var(--viewBodyColor) !important;
}

.webix_item_tab:first-of-type {
    border-left:0px solid var(--viewBodyColor) !important;
}

.webix_item_tab:last-of-type {
    border-right:0px solid var(--viewBodyColor) !important;
}

.um-role-icon i {
    font-size:1.1rem;
}