.batches-1,
.batches-2 {
    background-color: var(--anchorColor) !important;
    border: solid 1px var(--anchorColor) !important;
    color: #fff !important;
    text-align: center !important;
}

.batches-2 {
    background-color: #fff !important;
    color: #000 !important;
}

.add-batch-btn {
    border: dashed 1px #333 !important;
    border-radius: 2px !important;
}

.batches-1,
.batches-2,
.add-batch-btn {
    margin: 0 3px !important;
}

[class="webix_view batches-row webix_layout_line"] {
    height: 40px !important;
}

[view_id="ebmUnsassignedTasksList"] .webix_overlay, [view_id="ebmPackagesList"] .webix_overlay {
    color:#94A1B3;
    opacity: 0.8;
}


.webix_list_item.webix_selected {
    box-shadow: inset 2px 0 #1ca1c1;
}