/* Mobile */
.server-error {
  width: 60%;
  height: fit-content !important;
  margin: 30px auto !important;
  padding: 10px 30px;
  text-align: center;
  background-color: var(--primary);
  border-radius: 30px;
  box-shadow: 2px 2px 5px 4px #ececec;
  box-sizing: border-box;
  padding-bottom: 30px !important;
}

.server-error img {
  width: 70% !important;
  margin: 0 auto;
}

.server-error h1 {
  font-size: 1.2rem;
}

.server-error a {
  border-radius: 50px;
  cursor: pointer;
  background-color: #2c3e50;
  padding: 10px 40px !important;
  color: var(--primary);
  transition: all 0.3s ease;
}

.server-error a:hover {
  opacity: 0.8;
}

/* mini ipad screen*/
@media screen and (min-width: 620px) {
}

/* max ipad and mini laptop screen*/
@media screen and (min-width: 960px) {
}

/* desktop screen*/
@media screen and (min-width: 960px) {
}
