.project__contact__modal {
    margin-top: 10px;
    border-radius: 10px;
    border: 0 !important;
    padding: 0 !important;
}

.contact-details-layout {
    padding: 10px 0 !important;
}

.contact-details-layout,
.contact-details-layout p,
.contact-details-layout .webix_view {
    background-color: var(--windorColor) !important;
    color: var(--primary);
    text-align: center;
    padding: 0 !important;
    cursor: text;
    font-size: 1em;
}

.contact-details-layout p a {
    color: var(--anchorColor);
    text-decoration: underline;
}

.contact-details-layout img {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    border: solid 2px var(--primary);
    margin-top: 10px;
    border-radius: 50px;
}

.contact-details-layout__project__name {
    margin-top: 10px;
}
