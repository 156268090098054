.account__info__scrollview {
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    -moz-scroll-behavior: smooth;
    -o-scroll-behavior: smooth;
}

.members__header,
.company__info {
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
    color: #000;
    font-weight: 400;
}

.company-address-wrapper__items {
    padding-left: 16px;
    font-size: 13px;
    cursor: text;
}

.view--buttons .webix_button {
    width: 40% !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
    background-color: var(--anchorColor) !important;
    border: solid 1px #dfdfdf !important;
    box-shadow: 0 0 2px 1px #eee;
    border-radius: 50px !important;
    color: var(--primary) !important;
    margin-left: 15px !important;
    font-size: 0.8rem;
    font-weight: 100;
    transition: all 0.5s ease;
}

.account-info-member-container {
    width: 70%;
    height: fit-content !important;
    font-size: 0.88rem;
    cursor: text;
    background-color: #fff;
    box-shadow: 1px 1px 5px #dddddd;
    border-radius: 5px;
    padding: 20px 10px !important;
    border-top: solid 2px var(--anchorColor) !important;
    border-bottom: solid 2px var(--anchorColor) !important;
    margin-bottom: 40px !important ;
    margin-left: 10px !important;
}

.account-info-member-container p a {
    color: #3686e7;
    cursor: pointer;
    text-decoration: underline;
}

.account-info-member-container p {
    margin: -3px 0;
}

/* mobile styles */
@media screen and (max-width: 400px) {
    html {
        font-size: 14px;
    }
}

/* ipad styles */
@media only screen and (min-width: 600px) and (max-width: 900px) {
    html {
        font-size: 14px;
    }
    .company__info {
        margin-left: -20px !important;
    }

    .company-address-wrapper__items {
        padding-left: 5px;
    }

    .members-wrapper {
        padding-left: 5px !important;
    }

    .account-info-member-container {
        box-sizing: border-box !important;
        width: 90%;
    }

    .view--buttons .webix_button {
        width: 60% !important;
    }
}
